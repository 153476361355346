/*@import 'firebaseui/dist/firebaseui.css';*/

/*@font-face {*/
/*    font-family: 'Geologica';*/
/*    src:*/
/*        local('Geologica'),*/
/*        url('/public/fonts/Geologica-VariableFont_CRSV\,SHRP\,slnt\,wght.ttf') format('truetype');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*}*/

/* Thin */

@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@300;700&display=swap');

@font-face {
    font-family: 'Geologica';
    src:
        local('Geologica Thin'),
        url('/public/fonts/static/woff2/Geologica_Auto-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/* Extra Light */
@font-face {
    font-family: 'Geologica';
    src:
        local('Geologica Extra Light'),
        url('/public/fonts/static/woff2/Geologica_Auto-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

/* Light */
@font-face {
    font-family: 'Geologica';
    src:
        local('Geologica Light'),
        url('/public/fonts/static/woff2/Geologica_Auto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* Regular */
@font-face {
    font-family: 'Geologica';
    src:
        local('Geologica Regular'),
        url('/public/fonts/static/woff2/Geologica_Auto-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* Medium */
@font-face {
    font-family: 'Geologica';
    src:
        local('Geologica Medium'),
        url('/public/fonts/static/woff2/Geologica_Auto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Semi Bold */
@font-face {
    font-family: 'Geologica';
    src:
        local('Geologica Semi Bold'),
        url('/public/fonts/static/woff2/Geologica_Cursive-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* Bold */
/* @font-face {
    font-family: 'Geologica';
    src:
        local('Geologica Bold'),
        url('/public/fonts/static/woff2/Geologica_Auto-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
} */

/* Extra Bold */
@font-face {
    font-family: 'Geologica';
    src:
        local('Geologica Extra Bold'),
        url('/public/fonts/static/woff2/Geologica_Auto-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

/* Black */
@font-face {
    font-family: 'Geologica';
    src:
        local('Geologica Black'),
        url('/public/fonts/static/woff2/Geologica_Auto-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0c0f21;
}

html {
    height: 100%;
    /* background-color: #0c0f21; */
}

* {
    scroll-behavior: smooth;
}

img {
    max-width: 100%;
    height: auto;
}

.swiper * {
    box-sizing: border-box !important;
}

.swiper .swiper-wrapper {
    margin-bottom: 18px;
    height: 177px;
}

.swiper-slide {
    width: 352px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper .swiper-pagination-bullet {
    background-color: #01cffe;
    width: 12px;
    height: 12px;
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
    margin-top: 0;
    position: absolute;
    left: calc(50% + 8px);
    /* right: auto; */
    top: 227px;
    height: 40px;
    width: 40px;
}

.swiper .swiper-button-prev {
    left: calc(50% - 48px);
}

.swiper.swiper-rtl .swiper-button-prev,
.swiper.swiper-rtl .swiper-button-next {
    right: calc(50% + 8px);
}

.swiper.swiper-rtl .swiper-button-next {
    right: calc(50% - 48px);
}

@media (min-width: 1121px) {
    .swiper .swiper-button-prev,
    .swiper .swiper-button-next {
        left: auto;
        right: 0;
        top: 0;
    }
    .swiper .swiper-button-prev {
        right: 56px;
    }

    .swiper.swiper-rtl .swiper-button-prev,
    .swiper.swiper-rtl .swiper-button-next {
        right: auto;
    }
    .swiper.swiper-rtl .swiper-button-next {
        left: 56px !important;
    }
    .swiper.swiper-rtl .swiper-button-prev {
        left: 0 !important;
    }
}

.swiper .swiper-button-prev::after {
    content: url('~/public/images/arrow-prew.svg') !important;
    height: 40px;
    width: 40px;
}
.swiper .swiper-button-next::after {
    content: url('~/public/images/arrow-next.svg') !important;
    height: 40px;
    width: 40px;
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        &[style*="overflow:hidden"],
        &[style*="overflow: hidden"] {
            touch-action: none; /*Fix for Safari bug with overflow hidden property*/
        -ms-touch-action: none;
        }
    }
}
